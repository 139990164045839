import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  padding: 32px 16px;
  margin: 0 auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 42px;
  }
`;
